<template>
    <div>
       
        <select v-model="selectedCountry" class="form-select" @change="updateCountryInfo">
            <option v-for="country in countryData" :key="country.country_code" :value="country.country_code">{{ country.country_name }}</option>
        </select>
    </div>
</template>


<script>
import { ref, onMounted, getCurrentInstance, computed, watch } from 'vue';
import { useStore } from 'vuex';
//import router from '../router/index';
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
    data() {
        return {
            countryData:[]
        }
    },
     props: {
        selectedCity: {
            type: Object,
            default: null
        }
    },
    methods: {
         async fetchCountryData() {


            try {
                const response = await axios.get('country'); // Replace with your API endpoint
                this.countryData = response.data;
              // console.log('county',this.countryData);
            } catch (error) {
                console.error('Error fetching country data:', error);
            }
        },
    },
    setup(props) {
        const selectedCountry = ref('');
        const countries = ref([]);
        const countryCapital = ref('');
        const store = useStore();
        const { emit } = getCurrentInstance();
        const selectedCityData = ref([]);
       // const cookieCountryCode= ref('');

         const Citydata = computed(() => {
            return props.selectedCity;
        });

        

        async function fetchCityIdcountryCode(id) {
            //this.selected_country_code = storedCountryName.value;
            //this.selected_country_code = this.storedCountryName;
            // console.log('Selected Category:', selectedcategory.value.id);

            try {
                const response = await axios.get(`countryCode?country_id=${id}`);

                selectedCountry.value = response.data; //
                 fetchCountryCapital(selectedCountry.value);

            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle errors here
            }

        }

        watch(Citydata, (newValue) => {
            // Call an async function when the value changes
            selectedCityData.value = newValue;
            //console.log('selectedCityData countryId', selectedCityData.value.country_id,'selectedCityData cityId', selectedCityData.value.id);
            fetchCityIdcountryCode(selectedCityData.value.country_id);
        });
        

        onMounted(() => {
            if(Cookies.get("CookiesCountryCode")){
               
                    selectedCountry.value = Cookies.get("CookiesCountryCode");
                    //console.log('Cookie Country code',selectedCountry.value);
                    fetchCountryCapital(Cookies.get("CookiesCountryCode"));
            }else{
                // Fetch IP-based country if available
                fetch('https://ipapi.co/json')
                    .then((response) => response.json())
                    .then((data) => {
                        const country = data.country;
                        if (country) {
                            selectedCountry.value = country;
                            //console.log('ipbased country',country);
                            Cookies.set("CookiesCountryCode", country, { expires: 6 });
                            fetchCountryCapital(country);
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching IP info:', error);
                    });
            }
            

        /*    // Fetch all listed countries
            fetch('https://restcountries.com/v3.1/all')
                .then((response) => response.json())
                .then((data) => {
                    countries.value = data.map((country) => ({
                        name: country.name.common,
                        code: country.cca2,
                    }));
                })
                .catch((error) => {
                    console.error('Error fetching countries:', error);
                }); */

                

                //console.log('cookieCountrycode',Cookies.get("CookiesCountryCode"))
        });  

        const fetchCountryCapital = (countryCode) => {
            fetch(`https://restcountries.com/v3.1/alpha/${countryCode}`)
                .then((response) => response.json())
                .then((data) => {
                    const capital = data[0]?.capital?.[0] || 'Unknown';
                    countryCapital.value = capital;
                    store.dispatch('updateCountryInfo', { name: selectedCountry.value, capital });

                })
                .catch((error) => {
                    console.error('Error fetching country capital:', error);
                });


        };

        const updateCountryInfo = () => {
            const selectedCountryCode = selectedCountry.value;
           // emit('country-selected', selectedCountry.value); // Use emit directly
            emit('custom-event-name', selectedCountry.value);
           // console.log('selected country code',selectedCountry.value);
            Cookies.set("CookiesCountryCode", selectedCountry.value, { expires: 6 });
          //  console.log('cookieCountrycode',Cookies.get("CookiesCountryCode"))
            const selectedCountryObject = countries.value.find(country => country.code === selectedCountryCode);
            if (selectedCountryObject) {
                fetchCountryCapital(selectedCountryCode);
            }
           // router.push('/');
             window.location.reload();
        };

        return {
            selectedCountry,
            countries,
            countryCapital,
            updateCountryInfo,
            selectedCityData, Citydata,
            fetchCityIdcountryCode

        };
    },
    mounted() {
        this.fetchCountryData();
        
    },
};
</script>


<style scoped>

h2 {
    color: black;
}
</style>
