<script>
import { RouterView } from "vue-router";
import HeaderView from '@/Header/HeaderView.vue'
import FooterView from '@/Footer/FooterView.vue'
import baseUrlMixin from "@/baseUrlMixin";
import Cookies from 'js-cookie';
//import CityDropdown from '@/Header/CityDropdown.vue'

export default {
  components: {
    HeaderView,
    FooterView,
    RouterView,
   // CityDropdown
  },
   data() {
    return {
      eventValue: null,
      selectedCity:[],
      cityImage: `baseUrl() +./assets/images/background.webp`,
     // dinamicCityImage:'',
     selectedLanguage:''
    };
  },
   computed: {
    bodyClass() {
      // Check if a city is selected
      if (this.selectedCity.city_image) {
        return 'selectedCityBackground'; // Apply this class if a city is selected
      } else {
        return 'defaultBackground'; // Apply this class if no city is selected
      }
    },
    cookieLanguage(){
      return Cookies.get("CookiesLanguageCode");
    }
     
  },
  methods: {
    handleCustomEvent(value) {
      this.eventValue = value; // Capture the emitted value
      this.$emit('custom-country-name', this.eventValue);
    },
    handleselecetdCityEvent(value){
      this.selectedCity = value;
      // Check if a city is selected
      if (this.selectedCity.city_image) {
        this.cityImage = baseUrlMixin.computed.baseUrl() + this.selectedCity.city_image;
      } else {
        this.cityImage = `baseUrl() +./assets/images/background.webp`;
      }
    },
     handleLanguageselect(value) {
      this.selectedLanguage = value;
     // console.log('Selected Language:', value);
      // Do something with the selected language in the grandparent component
    }
  },
  mounted() {
    if (this.cookieLanguage == null) {
      Cookies.set("CookiesLanguageCode", 'en', { expires: 6 });
    }
  },
  
};
</script>


<template>
  <div class="body" v-bind:style="{background: 'url('+this.cityImage+')'}">
    <div class="container">
    
      <HeaderView @custom-country-name = "handleCustomEvent" @getselectedlanguage = "handleLanguageselect" :selectedCity="selectedCity"/>
  <!--    <CityDropdown @getSelectedCity = "handleselecetdCityEvent"/>  -->

  
    
      <RouterView @getSelectedCity = "handleselecetdCityEvent" :selectedLanguage = "selectedLanguage"/>

    
     
    </div>

    <FooterView/>
  </div>

   

</template>

<style scoped>
.body {
 background: url("./assets/images/background.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
 .selectedCityBackground {
  
     background-size: cover;
     background-repeat: no-repeat;
    background-attachment: fixed;
    /* Other background styles */
  }

  .defaultBackground {
   
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* Other background styles */
  }
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}

@media (max-width: 577px){
#footer{
  display: none;
}
}
</style>
