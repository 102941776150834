import './assets/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import '@fortawesome/fontawesome-free/css/all.css';

import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router/index';
import store from './store';
import axios from 'axios';
import baseUrlMixin from './baseUrlMixin';
import "vue-search-select/dist/VueSearchSelect.css"
import '@splidejs/vue-splide/css/skyblue';
import '@splidejs/vue-splide/css/sea-green';


import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import VueSplide from '@splidejs/vue-splide';

import vue3StarRatings from "vue3-star-ratings";

axios.defaults.baseURL = baseUrlMixin.computed.baseUrl() + 'api/v1/';


const app = createApp(App)
app.component("vue3-star-ratings", vue3StarRatings);
app.mixin(baseUrlMixin);
app.use(router);
app.use(i18n);
app.use(store);
app.use(LoadingPlugin);
app.use( VueSplide );
app.mount('#app');
