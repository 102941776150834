export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "deals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deals"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
  "socialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Deal"])},
  "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received NewsLetter"])},
  "dealsinflevoland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deals in Flevoland"])},
  "dealsnear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deals near"])},
  "RestaurantdealsFlevoland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant deals Flevoland"])},
  "HoteldealsFlevoland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel deals Flevoland"])},
  "Howitworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
  "Vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancies"])},
  "Internships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internships"])},
  "Developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer"])},
  "SharingisCaring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sharing is Caring"])},
  "RecommendsocialDealandearn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommend social Deal and earn"])},
  "Spontaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spontaan"])},
  "LastMinuteDinnerapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Minute Dinner app"])},
  "LastMinuteDinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Minute Dinner"])},
  "luxurygateway.com-hoteldeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["luxurygateway.com - hotel deals"])},
  "SocialDealexperiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Deal Experiences"])},
  "Privacypolicy/GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy /GDPR"])},
  "Bugbountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug Bountry"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
  "GiftWrap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift Wrap"])},
  "SocialDealGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Deal Gift Card"])},
  "CustomerService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service"])},
  "DownloadtheSocialDealapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the Social Deal app"])},
  "Frequentlyaskquestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently ask questions"])},
  "sharingisCaring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sharing is Caring"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "Business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
  "CollabratewithVanakkamtamila": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collabrate with Vanakkamtamila"])},
  "AdvertiseonVanakkamtamila": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertise on Vanakkamtamila"])},
  "PromoteyourCompanyvanakkamtamila": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promote your Company on Vanakkamtamila"])},
  "WorkatSocialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work at Social Deal"])},
  "DoyouinternshipatSocialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you internship at Social Deal"])},
  "WorkasadeveloperatSocialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work as a developer at Social Deal"])},
  "PartnerPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner Portal"])},
  "SocialDealPartnerapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Deal Partner app"])},
  "Companyinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company info"])},
  "netharland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netharland"])},
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
  "directions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directions"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlights"])},
  "thefineprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tha Fine Print"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "timeremaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Remaining"])},
  "fofree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fo Free"])},
  "shareoffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["share this deal with your friends.If three friends buy the same deal then your voucher is ! free for you !"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "perfect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect"])},
  "howdoesitworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does it works"])},
  "PromoteyourCompanyonSocialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promote your company on Social Deal"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
  "profileinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Info"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
  "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "productReviewRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Review request"])},
  "eventReviewRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Review request"])},
  "findlocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Location"])},
  "storeReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Store Review"])}
}