<script>


import VendorLogin from '../components/VendorLogin.vue';
import UserLogin from '../components/UserLogin.vue';
import FeedBack from '../components/FeedbackPopup.vue';

export default {

    components: {
       
        VendorLogin,
        UserLogin,
        FeedBack

    },
    props: {
       
    },
    data() {
        return {
           
        };
    },
    computed: {
       

    },
    methods: {
       
        async clickloginPopup() {
            await this.$refs.vendorLogin.show({

            })

        },
        async clickUserloginPopup() {
            await this.$refs.userLogin.show({

            })
        },
        async clickFeedbackPopup() {
            await this.$refs.feedBack.show({

            })
        },
    },
    setup() {
       
        

       




        return {
           

        };
    },

    mounted() {
       
    },
};
</script>


<template>
    <VendorLogin ref="vendorLogin"></VendorLogin>
       <UserLogin ref="userLogin"></UserLogin> 
       <FeedBack ref="feedBack"></FeedBack> 
    <!--footer start-->

    <div id="footer" style="max-width: 100%; overflow-x: hidden;">
        <div class=" card">
            <div class="p-4 bg-dark text-white text-center">
                <div class="row" id="footerHead">
                    <p>
                        <span class="input-group">
                            <input type="text" class="footerButton" placeholder="Receive news letter" disabled>
                            <span class="input-group-text" id="basic-addon2">
                                <a href="#"><i class="fa-solid fa-envelope"></i></a>
                            </span>
                        </span>
                    </p>
                </div>
                <div class="row">

                    <div class="col-8 mx-auto">
                        <div class="row " id="footerTable">
                            <div class="col-lg-3 col-md-3 col-sm-6 footercoloum">
                                <table>
                                     <tr>
                                        <th>{{ $t("socialDeal") }}</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <RouterLink to="/SocialDeal/Howitworks">
                                                {{ $t("Howitworks") }}
                                            </RouterLink>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <RouterLink to="/SocialDeal/Privacy">
                                                    {{ $t("Privacypolicy/GDPR") }}
                                                </RouterLink></td>
                                    </tr>
                                 <!--   <tr>
                                        <td>
                                            <RouterLink to="/Business/Collabrate">
                                                {{ $t("dealsnear") }}
                                            </RouterLink>
                                        </td>
                                            
                                    </tr>   -->
                                <!--   
                                    <tr>
                                        <td>{{ $t("dealsinflevoland") }}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td> {{ $t("RestaurantdealsFlevoland") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("HoteldealsFlevoland") }}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td>{{ $t("Vacancies") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("Internships") }}Internships</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("Developer") }}Developer ?</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("SharingisCaring") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("RecommendsocialDealandearn") }}£5</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("Spontaan") }}Spontaan</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("LastMinuteDinnerapp") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("LastMinuteDinner") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("luxurygateway.com-hoteldeals") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("SocialDealexperiences") }}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td>{{ $t("Bugbountry") }}</td>
                                    </tr>   -->

                                </table>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 footercoloum">
                                <table>
                                     <tr>
                                            <th>{{ $t("Account") }}</th>
                                        </tr>
                                     <tr>
                                        <td> 
                                             <a  href="javascript:void(0);" @click="clickUserloginPopup">
                       
                                                {{ $t("userlogin") }}
                                            </a>

                                        </td>
                                    </tr>
                                     <tr>
                                        <td> 
                                            <a  href="javascript:void(0);" @click="clickloginPopup">
                         
                                                {{ $t("vendorlogin") }}
                                            </a>
                                        </td>
                                    </tr>
                               <!--    
                                    <tr>
                                        <td>{{ $t("SignUp") }}</td>
                                    </tr>
                                   
                                    <tr>
                                        <td>{{ $t("Account") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("Balance") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("Vouchers") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("GiftWrap") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("SocialDealGiftCard") }}</td>
                                    </tr>  -->
                                </table>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 footercoloum">
                                <table>
                                    <tr>
                                        <th>{{ $t("CustomerService") }}</th>
                                    </tr>
                                     <tr>
                                            <td>
                                                 <RouterLink to="/CustomerService/FAQ">
                                                       {{ $t("Frequentlyaskquestions") }}
                                                    </RouterLink>
                                            </td>
                                        </tr>
                                         <tr>
                                            <td>
                                               <RouterLink to="/Business/Collabrate">
                                                    {{ $t("howdoesitworks") }}?
                                                </RouterLink> 
                                            </td>
                                        </tr>
                                        
                                  <!--  
                                    <tr>
                                        <td>{{ $t("DownloadtheSocialDealapp") }}</td>
                                    </tr>
                                   
                                   
                                    <tr>
                                        <td>{{ $t("sharingisCaring") }}</td>
                                    </tr> -->
                                   

                                </table>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 footercoloum">
                                <table>
                                     <tr>
                                                <th>{{ $t("Business") }}</th>
                                            </tr>
                                   
                                    <tr>
                                        <td>
                                            <RouterLink to="/Business/Collabrate">
                                                {{ $t("CollabratewithVanakkamtamila") }}
                                            </RouterLink>
                                             
                                        </td>
                                    </tr>
                                    <tr>
                                        
                                        <td>
                                             <RouterLink to="/Business/advertisement">
                                                    {{ $t("AdvertiseonVanakkamtamila") }}
                                                </RouterLink>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                                 <RouterLink to="/Business/Promote">
                                                        {{ $t("PromoteyourCompanyvanakkamtamila") }}
                                                    </RouterLink>
                                            </td>
                                       
                                    </tr>
                                     <tr>

                                        <td>
                                            <RouterLink to="/Business/ContactUs">
                                                {{ $t("Contact") }}
                                            </RouterLink>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                                <RouterLink to="/Business/CompanyInfo">
                                                   {{ $t("Companyinfo") }}
                                                </RouterLink>
                                            </td>
                                       
                                    </tr>
                                 <!--   
                                    <tr>
                                        <td>{{ $t("WorkatSocialDeal") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("DoyouinternshipatSocialDeal") }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("WorkasadeveloperatSocialDeal") }}</td>
                                    </tr>
                                    <tr>
                                    <td>{{ $t("PartnerPortal") }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t("SocialDealPartnerapp") }}</td>
                                </tr> -->
                               



                            </table>
                        </div>
                    </div>
                <!--    <div class="row mt-3" id="footericon">
                        <ul>
                            <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                        </ul>
                    </div>
                -->
                </div>


            </div>
        </div>

        <div class="text-white text-center" id="footersubHead">
            <div class="row mt-3">
                <div class="col-8">
                    <div class="row">

                        <ul>
                            <li style="border-left: none;">
                                <i class="fa-solid fa-copyright"></i>
                                2023
                            </li>
                            <li>
                                Vanakamtamila
                            </li>
                            <li>
                                Netharland
                            </li>
                        </ul>

                    </div>
                </div>
                <div class="col-4">

                    <span class="input-group mb-3 ">
                        <input type="text" class="footerButton" placeholder="Receive news letter" disabled>
                        <span class="input-group-text" id="basic-addon2"><a href="#"><i
                                    class="fa-solid fa-envelope"></i></a></span>
                    </span>

                </div>

            </div>

        </div>

        <div class="mb-3">
            <div class="row">
                <div class="col bg-light" id="emptyRow">

                </div>
            </div>

        </div>

    </div>
</div>


<!--footer end -->
</template>

<style scoped>

#footer span span i {
    color: #fff;
    align-items: center;
    margin-left: 0 !important;
}
tr td a{
    text-decoration: none;
    color: #fff;
}

tr td a:hover{
   
    color: #28afe0;
}

@media screen and (max-width: 676px){
#footerTable .footercoloum {
    text-align: center;
    display: grid;
    align-items: center;
}

}
</style>