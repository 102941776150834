

<template>
     <div class="popups" :class="{ 'show-popups': isPopupVisible }">
              <div class="popups-content" style="text-align:center;">
    
              <p style="color:green;">Feedback was send Successfully</p>

    
              </div>
          </div>
    <popup-modal ref="popup">

         
        
            <div class="container-fluid">
                <div id="reg-login">
                    <div class="wrappers">
                       <div class='row' v-if="success">
                            <div class="alert alert-success alert-block">

                                <strong style="text-align: center; align-items: center; display: block; "> Your Feedback Was Send Successfully. </strong>
                            </div>
                        </div>


          

              <div class="row mb-2">
                    <div class="col">
                        <span style=" font-size: 20px; font-weight: 500; ">Feedback</span>
                       
                    </div>
                    <div class="col" id="login-icon">
                        <a @click="_cancel">
                            <i class="fa-solid fa-xmark"></i>
                        </a>
                    </div>
              </div>


               <div class="login-forms" id="reg-form">

                    <form @submit.prevent="submitFeedbackForm" class="vl-parent" ref="formContainer" id="form-reg">
                        <div class="input-box mt-3">
                            
                                <input type="text" id="name" placeholder="Enter Your Name" v-model="formData.name"
                                    name="email" required>

                            </div>
                        <div class="input-box mt-3">
                            <span v-if="registrererror.email" class="error-message mb-3">{{
                                registrererror.email }}</span>
                        <!--    <label class="small mb-1 mt-3" required for="Email"><span class="required">*</span> Email</label>  -->
                                <span v-if="registrererror.email" class="error-message mb-3">{{
                                    registrererror.email }}</span>
                            <input type="email" id="email" placeholder="Enter Your Email" v-model="formData.email"
                                name="email" required>

                        </div>

                            <div :class="['input-box ', { 'error-validation': registrererror.email }]" style=" margin-top: 16px !important; mb-1 mt-3">
                                <!--  <label class="small mb-1 mt-3" required for="inputsort_description"><span class="required">*</span> FeedBack</label>  -->
                                    <textarea class="form-control" rows="5" v-bind:value="formData.feedback" @input="formData.feedback = $event.target.value"
                                    placeholder="Enter Feedback"></textarea>

                            </div>

                            <div class="input-box mt-3" style=" margin-top: 120px !important;">
                                    <input type="Submit" value="Send Feedback" style="background:#00aeea; color:white;">
                                </div>


                    </form>
                </div>

            </div>
                </div>
            </div>
    </popup-modal>
    <!--pop up reg/login form start-->
   

<!--pop up reg/login form end-->
</template>

<script>
import PopupModal from './PopupModal.vue'
//import {  ref } from 'vue';
//import { RouterLink } from "vue-router";
//import { useStore } from 'vuex';


//import router from '../router/index';
import axios from 'axios';

export default {
    name: 'ConfirmDialogue',

    components: { 
    PopupModal,
   //  RouterLink,
    
    },

    data: () => ({
        // Parameters that change depending on the type of dialogue
        title: undefined,
        message: undefined, // Main text content
        okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: 'Cancel', // text for cancel button

        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
       formData: {
            name: '',
            email: '',
            feedback: ''

        },
        registrererror: '',
        success: false,
        isPopupVisible: false,
        timer: null, // Timer variable
         shows: false,
         fullPage: true
    }),

    computed: {
       
        
    },

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
         resetForm() {
             this.formData = {
                name: '',
                email: '',
                feedback: ''

            };
        },

        _confirm() {
            
            this.success = false;
            this.resetForm();
            this.$refs.popup.close()
            this.resolvePromise(true)
        },

        _cancel() {
           // window.location.reload();
           this.success = false;
           this.resetForm();
            this.$refs.popup.close()
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User canceled the dialogue'))
        },

      async submitFeedbackForm() {



            // Check if the passwords match and perform form submission logic
            //console.log(this.formData);
             let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: true,
                onCancel: this.onCancel,
            });
           

            try {
                const response = await axios.post('feedbackSend', this.formData);
                loader.hide();
                if (response.data.data == 'success') {
                     
                    this.isPopupVisible = true; // Show the popup
                    // Start a timer to hide the popup and navigate after 10 seconds
                    this.timer = setTimeout(() => {
                        this.isPopupVisible = false; // Hide the popup
                       
                        window.location.reload(); // Navigate to '/vendordashboard'
                    }, 1000);
                }
            } catch (error) {
                loader.hide();
                console.error('Error send Email Feed back:', error);

            }
        },

        onCancel() {
            console.log('User cancelled the loader.')
        }

    },
     mounted() {

      

    }
}
</script>



<style scoped>


.popups {
     display: none;
    background-color: rgba(136, 131, 131, 0.5);
    justify-content: center;
    align-items: center;
    top: 10px;
    z-index: 9;
}
.show-popups {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popups-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    
}
.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.ok-btn {
    color: red;
    text-decoration: underline;
    line-height: 2.5rem;
    cursor: pointer;
}

.cancel-btn {
    padding: 0.5em 1em;
    background-color: #d5eae7;
    color: #35907f;
    border: 2px solid #0ec5a4;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
}

.forgot-email{
  text-decoration: underline;
  color:#00aeea;
  cursor: pointer;
}

@media screen and (max-width: 486px){
.login-class a{
  font-size: 18px !important;
  text-decoration: none;
  cursor: pointer;
 
}
}
#login-icon{
  text-align: end;
  
}
#login-icon i{
  font-size: 27px;
}
.login-class{
      text-align: center;
    height: 61px;
    padding-top: 11px;
     background: white;
}

.login-class:hover{
 color:#00aeea;
  border-bottom:2px solid #00aeea !important;
}
.selected-login{
  color: #00aeea;
  border-bottom:2px solid #00aeea !important;
}
.login-class a{
  font-size: 19px;
  text-decoration: none;
  cursor: pointer;
 
}
.login-button{
  background: rgb(248, 243, 243);
  
}
.error-validation {
    margin-top: 46px !important;
}

.error-message {
    color: red;
}

.popup {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

#reg-login .wrappers ul li {
    display: inline-block;

    text-align: center;

    font-size: 25px;
    cursor: pointer;
}

#reg-login .wrappers ul li:last-child {
    position: absolute;
    margin-top: 0px;
    margin-left: 36px;
    font-size: 35px;
}

.btn1 {
    height: 3.5rem;
    padding: 0 2rem;
    border: 0.1rem solid #000;
    border-radius: 3.12rem;
    box-shadow: 0 0.4rem 0 0 #000;
    background-color: #fff;
    color: #000;
    font-family: "Epilogue", sans-serif;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s;

}

.selected {
    border: 0.1rem solid #00aeea;
}

.btn1:hover {
    transform: translateY(0.3rem);
    box-shadow: 0 0.2rem 0 0 #000;
}
</style>